import React from 'react';
// import { Location } from "@reach/router";
import LanguageSelector from 'components/common/LanguageSelector';

import Layout from '../components/common/layout';

const NotFoundPage = ({lang, langKey, location}) => {
  const language = lang;
  let isEn = false;
  if (language === "en") {
    isEn = true;
  }
  const content = {
    title: {
      en: "Not Found",
      is: "Fannst ekki"
    },
    copy: {
      en:
        "The page you are trying to view does not exist, or is not available in the selected language. You can try a different language: ",
      is:
        "Síðan sem þú ert að reyna að skoða er ekki til, eða hefur ekki verið þýdd. Þú getur prófað að velja annað tungumál: "
    }
  };
  return (
    <Layout location={location}>
      <div id="wrapper">
        <section id="main" className="wrapper">
          <div className="inner">
            <h1>{isEn ? content.title.en : content.title.is}</h1>
            <p>{isEn ? content.copy.en : content.copy.is}</p>
            <p>
              <LanguageSelector
                lang={lang}
                location={location}
              />
            </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
