// import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

function LanguageSelector({ lang, location, classNames }) {
  const url = location.pathname;
  if (lang === "en") {
    return <Link to={`/is${url}`} className={classNames}>Íslenska</Link>;
  } else {
    return (
      <Link to={url.replace("/is/", "/")} className={classNames}>English</Link>
    );
  }
}
export default LanguageSelector;
